/* eslint-disable no-param-reassign */
<template>
  <div class="account">
    <PageSubHeader :pageSubHeader="pageSubHeader" />

    <main>
      <section id="form_detail">
        <div class="w1300">

          <!-- 表單主標題 -->
          <div class="form_main_title_box">
            <h3 class="form_main_title txt-bold">費用查詢</h3>
            <div class="img_box">
              <img src="@/statics/img/index/icon_title2.svg" alt="">
            </div>
          </div>

          <form
            name="form_account_search_fee"
            id="form_account_search_fee"
            action="application_success.php"
            method="post"
          >

            <div class="filter_box payment_status">
              <select
                id="payment_status"
                name="payment_status"
                v-model="filter.paymentStatus"
                @change="filterData"
              >
                <option value="" disabled selected>篩選繳費狀態</option>
                <option value="全部">全部</option>
                <option value="未繳費">未繳費</option>
                <option value="已繳費">已繳費</option>
              </select>
            </div>

            <!-- table area -->
            <div class="table_box">

              <!-- select all for mobile -->
              <div class="checkbox_container checkbox_container-mobile">
                <label for="check_all_mobile" class="th">
                  <input
                    id="check_all_mobile"
                    name="check_all_mobile"
                    type="checkbox"
                    v-model="selectAll"
                    @change="toggleSelectAll"
                  >
                  <span class="checkmark"></span>
                </label>
              </div>

              <table class="txt-center">
                <thead>
                  <tr>
                    <th class="td-checkbox th">

                      <!-- select all for desktop -->
                      <div class="checkbox_container">
                        <label for="check_all_desktop" class="th">
                          <input
                            id="check_all_desktop"
                            name="check_all_desktop"
                            type="checkbox"
                            v-model="selectAll"
                            @change="toggleSelectAll"
                          >
                          <span class="checkmark"></span>
                        </label>
                      </div>

                    </th>
                    <th class="td-main txt-left"><p>{{ th1 }}</p></th>
                    <th class="min100"><p>{{ th2 }}</p></th>
                    <th class="min100"><p>{{ th3 }}</p></th>
                    <th class="min150"><p>{{ th4 }}</p></th>
                    <th class="min100"><p>{{ th5 }}</p></th>
                    <th class="min100"><p>{{ th6 }}</p></th>
                  </tr>
                </thead>
                <tbody id="table_data">
                  <tr v-for="tr in tableData" :key="tr.id">
                    <td
                      data-title="繳費"
                      class="td-checkbox"
                    >
                      <div class="checkbox_container" v-if="tr.status === '未繳費'">
                        <label :for="tr.id">
                          <input
                            :id="tr.id"
                            v-model="tr.checked"
                            class="check_item"
                            type="checkbox"
                            @change="cancelSelectAll(tr.id, tr.checked)"
                          >
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </td>
                    <td :data-title="th1" class="td-main txt-left"><p>{{ tr.name }}</p></td>
                    <td :data-title="th2"><p>{{ tr.year }}</p></td>
                    <td :data-title="th3"><p>{{ tr.price }}</p></td>
                    <td :data-title="th4" class="td-input">
                      <template v-if="tr.status === '未繳費'">
                        <input
                          v-model.trim="tr.receiptTitle"
                          type="text"
                          placeholder="請輸入收據抬頭"
                        >
                      </template>
                      <template v-else-if="tr.status === '已繳費'">
                        <p>{{ tr.receiptTitle }}</p>
                      </template>
                    </td>
                    <td :data-title="th5" class="min100"><p>{{ tr.status }}</p></td>
                    <td :data-title="th6" class="td-download">
                      <a
                        v-if="tr.status === '已繳費'"
                        href="#/"
                        class="small"
                        download
                      >下載收據</a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="payment_check txt-right">
              <p class="payment_check_total">
                總金額 <span class="txt-light_green">{{ totalAmount }}</span> 元
              </p>
              <div class="filter_box payment_check_container">
                <Select
                  id="pay_type"
                  v-model="user.paymentType"
                  :item="inputFormat.paymentSelect"
                  :rules="[
                    val => !!val || '必選欄位！',
                  ]"
                />
                <!-- 信用卡繳費：綠界金流 -->
                <button
                  type="button"
                  class="btn p"
                  @click="onSubmit"
                >前往繳費</button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import { AccountSearchSubHeader } from '@/lib/const';
import PageSubHeader from '@/components/PageSubHeader.vue';
import Select from '@/components/form/Select.vue';
import { request, toFormData } from '@/boot/axios';

export default {
  name: 'AccountSearchFee',
  data() {
    return {
      ...AccountSearchSubHeader,
      selectAll: false,
      filter: {
        paymentStatus: '',
      },
      user: {
        name: '王小明',
        paymentType: '',
        paymentTarget: [],
      },
      inputFormat: {
        paymentSelect: {
          placeHolder: '請選擇繳費方式',
          options: [
            '信用卡',
            // 'ATM轉帳',
            // '超商代碼繳費',
          ],
        },
      },
      th1: '費用名稱',
      th2: '年度',
      th3: '金額',
      th4: '收據抬頭',
      th5: '繳費狀態',
      th6: '操作',
      tableData: [
        {
          id: 1,
          checked: false,
          name: '醫師入會費',
          year: '2020',
          price: 2000,
          receiptTitle: '王小明',
          status: '已繳費',
          download: '',
        },
        {
          id: 2,
          checked: false,
          name: '醫師常年會費',
          year: '2020',
          price: 3000,
          receiptTitle: '王小明',
          status: '未繳費',
          download: '',
        },
        {
          id: 3,
          checked: false,
          name: '學術研討會報名費',
          year: '2020',
          price: 3000,
          receiptTitle: '王小明',
          status: '未繳費',
          download: '',
        },
      ],
    };
  },
  computed: {
    totalAmount() {
      let computedPrice = 0;
      this.tableData.forEach((data) => {
        if (data.checked && data.status === '未繳費') {
          computedPrice += data.price;
        }
      });
      return computedPrice;
    },
  },
  methods: {
    filterData() {
      if (this.filter.paymentStatus) {
        request({
          url: 'API',
          method: 'get',
          params: {
            paymentStatus: this.filter.paymentStatus,
          },
        }).then((result) => {
          this.tableData = result.tableData;
        });
        // axios.get('', {
        //   params: {
        //     paymentStatus: this.filter.paymentStatus,
        //   },
        // })
        //   .then((res) => {
        //     console.log(res);
        //   })
        //   .catch((error) => {
        //     console.log(error);
        //   });
      }
    },
    toggleSelectAll() {
      if (this.selectAll === true) {
        this.tableData.forEach((item) => {
          // eslint-disable-next-line no-param-reassign
          item.checked = true;
        });
        this.user.paymentTarget = this.tableData.filter((data) => data.status === '未繳費');
      } else {
        this.tableData.forEach((data) => {
          // eslint-disable-next-line no-param-reassign
          data.checked = false;
        });
        this.user.paymentTarget = [];
      }
    },
    cancelSelectAll(dataId, dataChecked) {
      if (this.selectAll === true) {
        this.selectAll = false;
      }
      if (dataChecked === true) {
        this.tableData.forEach((data) => {
          if (data.id === dataId) {
            this.user.paymentTarget.push(data);
          }
        });
      } else {
        this.user.paymentTarget = this.user.paymentTarget.filter((target) => target.id !== dataId);
      }
    },
    onSubmit() {
      const errorList = [];
      this.$children.forEach((item) => {
        if (item.$options.name && item.$options.name.match(/^Form/i)) {
          item.test();
          errorList.push(item.error);
        }
      });
      if (errorList.indexOf(true) === -1) {
        request({
          url: 'API',
          method: 'post',
          data: toFormData(this.user),
        });
        // axios.post('https://my-json-server.typicode.com/typicode/demo/posts', this.user)
        //   .then((res) => {
        //     console.log('success', res.data);
        //     if (this.user.paymentType === '信用卡') {
        //       // this.$router.push({ path: '/account/search/fee' });
        //     }
        //   })
        //   .catch((error) => {
        //     console.log(error);
        //   });
      } else {
        // console.log('必填欄位尚未完成！');
      }
    },
  },
  components: {
    PageSubHeader,
    Select,
  },
};
</script>
